@import "melange-css/melange.css";
@import "./book-illustrations.css";
@import "./excerpt.css";

:root {
  --mg-ff-serif: 'Baskerville', 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052, serif;
  --mg-ff-sans: 'Helvetica Neue', Helvetica, Inter, Roboto, 'Nimbus Sans', sans-serif;
  --mg-lh-solid: 1.1;
  --mg-lh-copy: 1.35;
/*
https://ghola.dev/?numColors=6&colorHex=%23becbd4&secondaryColorHex=%23434325&numShades=7&scaleModel=FixedHSLLogLightness&colorWheel=Monochrome&showColorDetails=true&showContrastInfo=true&bigSwatches=true&secondaryColorChecked=true
 */

  --mg-green-darkest: #212112; /* Rangoon Green */
  --mg-green-darker: #32321c; /* Birch */
  --mg-green-dark: #5c5c33; /* Verdigris */
  --mg-green: #bcbc86; /* Indian Khaki */
  --mg-green-light: #dcdcc0; /* Tana */
  --mg-green-lighter: #f8f8f2; /* Spring Wood */
  --mg-green-lightest: #fdfdfc; /* Pampas */

  --mg-blue-darkest: #141a1f; /* Bunker */
  --mg-blue-darker: #1f282f; /* Ebony Clay */
  --mg-blue-dark: #394a56; /* Oxford Blue */
  --mg-blue: #8ea5b4; /* Bali Hai */
  --mg-blue-light: #c5d0d8; /* Heather */
  --mg-blue-lighter: #f2f5f7; /* Athens Gray */
  --mg-blue-lightest: #fcfdfd; /* Porcelain */

  --mg-gray-darkest: #1a1a1a; /* Cod Gray */
  --mg-gray-darker: #272727; /* Mine Shaft */
  --mg-gray-dark: #484848; /* Tundora */
  --mg-gray: #a1a1a1; /* Silver Chalice */
  --mg-gray-light: #cecece; /* Alto */
  --mg-gray-lighter: #f5f5f5; /* Wild Sand */
  --mg-gray-lightest: #fcfcfc; /* Alabaster */
}

h1, h2, h3, h4, h5 {
  line-height: var(--mg-lh-title);
}

h1:not([class]),
h2:not([class]),
h3:not([class]),
h4:not([class]),
h5:not([class]) {
  margin: 0;
}

a {
  color: var(--blue-dark);
}

p:not([class]) {
  line-height: var(--mg-lh-copy);
  max-width: var(--mg-tw);
  margin-left: auto;
  margin-right: auto;
}


.toc ol {
  padding-left: var(--mg-sp-3);
  list-style-position: inside;
}

.col-2 { columns: 2; }
.break-inside-avoid { break-inside: avoid; }
@media screen and (min-width: 30em) {
  .col-2-ns { columns: 2; }
  .break-inside-avoid-ns { break-inside: avoid; }
}
