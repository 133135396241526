.excerpt h2,
.excerpt h3,
.excerpt h4 {
  text-align: center;
}

.excerpt h2:not([class]) {
  font-size: var(--mg-fs-5);
}

.excerpt h3:not([class]) {
  font-size: var(--mg-fs-4);
}

.excerpt h4:not([class]) {
  font-size: var(--mg-fs-3);
}

.excerpt h5 {
  font-size: var(--mg-fs-2);
  max-width: var(--mg-tw);
  margin: auto;
  text-transform: uppercase;
}
.excerpt h2:not([class]),
.excerpt h3:not([class]),
.excerpt h4:not([class]) {
  font-family: var(--mg-ff-sans);
}
