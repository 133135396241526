.book-illustrations {
  z-index: 1;
}
.book-illustrations.book-illustrations__ebook {
  transform: translateX(calc( -1 * var(--mg-sp-4) ))
             translateY(calc( 2 * var(--mg-sp-3) ));
}

.book-illustrations .book-illustrations__paperback-container {
  perspective: 1000px;
}

.book-illustrations .book-illustrations__paperback-container--nudged-right {
  transform: translateX(var(--mg-sp-4));
}

.book-illustrations .book-illustrations__paperback {
  width: var(--mg-sp-6);
  height: calc(var(--mg-sp-6) * 1.42);
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(-21deg);
  margin-top: var(--mg-sp-3);
  margin-bottom: var(--mg-sp-3);
}


.book-illustrations .book-illustrations__paperback > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--mg-sp-6);
  height: calc(var(--mg-sp-6) * 1.42); /* Image is 709 x 1000 */
  transform: translateZ(var(--mg-sp-3));/* # of pages */
  background-color: var(--mg-gray-dark);
  border-radius: 0 2px 2px 0;
  box-shadow: 0px 10px 5px -5px var(--mg-gray);
  border: solid thin var(--mg-gray-light);
}


.book-illustrations .book-illustrations__paperback::after {
  position: absolute;
  top: 0;
  left: 0;
  content: ' ';
  width: var(--mg-sp-6);
  height: calc(var(--mg-sp-6) * 1.42);
  transform: translateZ(-42.5px);
  background-color: var(--mg-gray-light); /* Color of the pages */
  border-style: solid;
  border-color: var(--mg-gray);
  border-width: 1px;
  border-radius: 2px; /* Corners of the "pages" */
  box-shadow: -4px 0 4px 4px var(--mg-gray-light);
}
